<template>
    <div>
        <div class="row my-0">
            <div class="col-12">
                <h1 class="mb-4">{{ textContent.Title }}</h1>
                <div v-for="(link, id) in textContent.Links" :key="id" class="mb-2">
                    <recess-button
                        :title="link"
                        variant="variant4"
                        class="d-flex"
                        @click.native.prevent="goToSelectedTitle(id)"
                    />
                </div>
                <div class="mb-4 mt-4">
                    <span class="mt-4">{{ textContent.Description[1] }}</span>
                    <router-link :to="{name: 'support-videos'}">{{ textContent.Description[2] }}</router-link>
                    <span> {{ textContent.Description[3] }}</span>
                </div>
            </div>
        </div>
        <recess-divider show-line />
        <div class="row my-0">
            <div class="col-12">
                <div v-for="(content, idx) in textContent.Content" :key="idx" :id="idx" class="mt-4">
                    <h3>{{ content.Title }}</h3>
                    <p v-html="content.Description"></p>
                    <div class="images-purchase-agreement mt-4 mb-4" v-if="contentImages[idx] && contentImages[idx].imageDescription">
                        <image-zoom
                            :src="contentImages[idx].imageDescription"
                            :alt="content.ListDescription" 
                            :close-on-background-click="true"
                        />
                    </div>
                    <p v-if="content.ListDescription"> {{ content.ListDescription }}</p>
                    <ul v-if="content.List" :class="content.ordered ? 'u-list-decimal mt-4' : 'u-list-unordered mt-4'">
                        <li v-for="(item, key) in content.List" :key="key">
                            <span v-if="content.vhtml" v-html="item"></span>
                            <span v-else>
                                {{ item }}
                                <div class="images-purchase-agreement mt-4 mb-4" v-if="contentImages[idx] && content.type !== 'single'">
                                    <image-zoom
                                        :src="contentImages[idx][key]"
                                        :alt="idx" 
                                        :close-on-background-click="true"
                                    />
                                </div>
                            </span>
                        </li>
                        <ul v-if="content.Subcontent" class="mt-4">
                            <li v-for="(sub, key) in content.Subcontent" :key="key">
                                {{ sub }}
                            </li>
                        </ul>
                    </ul>
                    <div v-if="contentImages[idx] && content.type == 'single'" class="mt-4">
                        <div v-for="image in contentImages[idx]" :key="idx" class="images-purchase-agreement mt-4 mb-4">
                            <image-zoom
                                :src="image"
                                :alt="idx" 
                                :close-on-background-click="true"
                            />
                        </div>
                        <p v-if="content.ImageDescription" v-html="content.ImageDescription"></p> 
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 d-flex justify-content-end">
                <recess-button variant="secondary" class="mt-4 mb-4" :title="SHARED.ButtonTexts.goToTop" @click.prevent.native="scrollToTop()" />
            </div>
        </div>
    </div>
</template>

<script>
import ImageZoom from "./ImageZoom.vue"; 

import picture1 from '../../../shared/src/assets/images/purchase-agreement/Picture1.jpg';
import picture2 from '../../../shared/src/assets/images/purchase-agreement/Picture2.jpg';
import picture3 from '../../../shared/src/assets/images/purchase-agreement/Picture3.jpg';
import picture4 from '../../../shared/src/assets/images/purchase-agreement/Picture4.jpg';
import picture5 from '../../../shared/src/assets/images/purchase-agreement/Picture5.jpg';

// Constants
import { SHARED } from "../../constants/EdumsSharedConstants.json";

export default {
    components: {
        ImageZoom
    },
    data() {
        return {
            SHARED,
            contentImages: {
                "Fourth": {
                    picture1
                },
                "Fifth": {
                    imageDescription: picture2,
                    1: picture3,
                    2: picture4,
                    3: picture5
                }
            }
        }
    },
    computed: {
        textContent() {
            return this.SHARED.SupportCenterPage.PurchaseAgreement
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        goToSelectedTitle(id) {
            let element = document.getElementById(id);
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }
}
</script>